<template>
  <div class="list-page">
    <Reminder v-loading="formLoading" title="数据公告" :textArr="reminderText" />
    <SourceScreen :severa="severa" :filterForm="filterForm" :page="pageData.current_page"
      :selectArr="selectArr" :uploadFilter="ok">
    </SourceScreen>
    <div class="list-btn" style="margin-top: 20px">
      <el-button type="danger" size="small" @click="handleRefund">退款</el-button>
    </div>
    <el-table v-loading="formLoading" style="width: 100%;margin-top: 20px" class="thead-light" stripe
      :data="list" @selection-change="handleSelectionChange">
      <!-- 操作 -->
      <el-table-column label="操作" width="70" fixed="right">
        <template slot-scope="scope">
          <el-button size="small" @click="refundRow(scope.row)" :disabled="scope.row.status !== 0"
            type="text">
            退款
          </el-button>
        </template>
      </el-table-column>
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column show-overflow-tooltip label="ID" prop="id" min-width="80"></el-table-column>
      <el-table-column label="用户名称" prop="nickname" min-width="110">
        <template slot-scope="scope">
          {{ scope.row.nickname | placeholder}}
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="phone" min-width="130">
        <template slot-scope="scope">
          {{ scope.row.phone | placeholder}}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="会内职务" prop="position" min-width="130">
        <template slot-scope="scope">
          {{ scope.row.position | placeholder}}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip :label="tenantVersion === 'school' ? '所属校友会': '所属组织'" prop="organization" min-width="130">
        <template slot-scope="scope">
          {{ scope.row.organization | placeholder}}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="届数" prop="the_number_text" min-width="120">
      </el-table-column>
      <el-table-column label="交易金额（元）" prop="price" sortable="custom" min-width="150"></el-table-column>
      <el-table-column show-overflow-tooltip label="订单号" prop="orderid" min-width="160"></el-table-column>
      <el-table-column label="订单状态" prop="order_status" min-width="100">
        <template slot-scope="{row}">
          <ListStatusText :text="row.order_status" :type="row.order_status === '已支付'? 'success':row.order_status === '未支付' ? 'warning':row.order_status === '已退款' ? 'danger':'info'"></ListStatusText>
        </template>
      </el-table-column>
      <el-table-column label="商户号" prop="merchant" min-width="120">
        <template slot-scope="scope">
          {{ scope.row.merchant | placeholder}}
        </template>
      </el-table-column>
      <el-table-column label="提交时间" prop="update_time" sortable="custom" min-width="160"></el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage"></Pagination>
    <ReasonDialog v-model="openDialog" :IDs="currentId" :updateList="RefreshList" />
  </div>

</template>

<script>
import { indexList, severa } from "../../finance/api/list";
import Pagination from "@/base/components/Default/Pagination";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import SourceScreen from "../components/SourceScreen";
import ReasonDialog from "../components/ReasonDialog.vue";
import Reminder from "@/modules/common/components/Reminder.vue";
import ListStatusText from "@/base/components/List/ListStatusText"

export default {
  data() {
    return {
      formLoading: false,
      // 数据公告
      reminderText: [
        "截止目前: 0000-00-00",
        "会费共收入: 0 (元)",
        "昨天会费收入: 0 (元)",
        "本周会费共收入: 0 (元)",
        "本月共收入: 0 (元)",
        "最近三个月会费共收入: 0 (元)",
      ],
      list: [],
      severa: [],
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        page_size: 15,
        status: -1,
        merchant: "",
        organization: "",
        user_keyword: "",
        orderid: "",
        number: -1,
      },
      pageData: {},
      selectArr: [],
      currentId: "",
      openDialog: false,
      text: '',
    };
  },
  components: {
    DatePeriodPicker,
    SourceScreen,
    Pagination,
    ReasonDialog,
    Reminder,
    ListStatusText
  },
  created() {
    this.getList(this.filterForm);
    this.getSevera();
  },
  methods: {
    //页面切换
    changPage(data) {
      this.filterForm.page_size = data.page_size;
      const requestData = { ...this.filterForm, ...data };
      this.getList(requestData);
    },
    getList(requestData) {
      this.formLoading = true;
      this.pageData = {};
      indexList(requestData)
        .then((res) => {
          this.list = res.data.data;
          this.pageData = res.data;
          this.text = this.tenantVersion === 'school' ? '校友会收入':'组织收入'
          this.reminderText = res.data.stat.time
            ? [
                `截止目前：${res.data.stat.time}`,
                `昨天会费收入：${res.data.stat.yesterday_price} (元)、本周会费共收入：${res.data.stat.week_price} (元)、本月共收入：${res.data.stat.month_price} (元)`,
                `最近三个月会费共收入: ${res.data.stat.month_three_price} (元)`,
                `会费共收入：${res.data.stat.total_price} (元)`,
                `${this.text}：${res.data.stat.organization_price || '-'}`,
                `历届收入：${res.data.stat.term_price || '-'}`,
              ]
            : [
                `统计时间：${res.data.stat.start_time} ~ ${res.data.stat.end_time}`,
                `会费共收入：${res.data.stat.total_price}`,
              ];
          this.formLoading = false;
        })
        .catch((err) => {
          this.formLoading = false;
        });
    },
    // 刷新列表
    RefreshList() {
      const data = {
        page: this.pageData.current_page,
        ...this.filterForm,
      };
      this.getList(data);
    },
    /**
     * 届数
     */
    getSevera() {
      severa()
        .then((res) => {
          this.severa = res.data;
        })
        .catch((err) => {});
    },
    // 批量退款
    handleRefund() {
      if (this.selectArr.length) {
        if (
          this.selectArr.findIndex(
            (el) => this.list.find((e) => e.id === el).status !== 0
          ) !== -1
        ) {
          this.$message.error("订单状态非已支付不能退款");
          return;
        }
        this.currentId = this.selectArr;
        this.openDialog = true;
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 退款
    refundRow(row) {
      this.currentId = row.id;
      this.openDialog = true;
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getList(this.filterForm);
    },
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  }
};
</script>

<style lang="scss" scoped>
.brand-content {
  min-height: 520px;
}
</style>
