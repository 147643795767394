import api from '@/base/utils/request';
import download from "@/base/utils/download";

// 列表数据
export const indexList = data => {
  return api({
    url: '/admin/cyc/Order/index',
    method: 'post',
    data
  });
};
//届数
export const severa = data => {
  return api({
    url: '/admin/cyc/Order/number',
    method: 'post',
    data
  });
};

// 获取所属组织列表
export const organizationOptions = data => {
  return api({
    url: '/admin/cyc/Organization/parentToList',
    method: 'post',
    data
  });
};

// 退款
export const refund = data => {
  return api({
    url: '/admin/cyc/order/refund',
    method: 'post',
    data,
    errorToast: false, // 错误提示由自定义 alert 展示
    notCancel: true // 批量调用时不要取消上一个
  });
};

// 导出列表
export const orderExport = data => {
  return download({
    url: '/admin/cyc/order/export',
    method: 'post',
    data,
    download: true
  });
};