<template>
  <el-dialog
    title="退款原因"
    :visible.sync="openDialog"
    width="400px"
    class="dialog-vertical"
  >
    <el-form
      size="medium"
      :model="_data"
      :rules="rules"
      label-position="top"
      label-width="70px"
      ref="why"
    >
      <el-form-item label="退款原因：" prop="why">
        <el-input
          type="textarea"
          :maxlength="50"
          show-word-limit
          :rows="4"
          v-model="why"
          placeholder="请填写退款原因"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="cancel('why')">取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="submit('why')"
        :loading="saveLoading"
        >保存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { refund } from '../api/list'
export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    IDs: {
      type: [String, Array],
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      rules: {
        why: [{ required: true, message: '请填写原因', trigger: 'blur' }],
      },
      why: '',
    }
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          // this.$emit("addFeeConfig",this.form)
          const data = {}
          data.why = this.why
          // data.id = this.IDs;

          let error = false

          Promise.all(
            (typeof this.IDs === 'string' ? [this.IDs] : this.IDs).map((d) => {
              return new Promise((resolve) => {
                if (error) {
                  console.log('已经出错了，停止批量请求')
                  resolve({ code: 0, msg: `数据${d}的退款操作已取消` })
                } else {
                  setTimeout(() => {
                    refund({
                      ...data,
                      id: [d],
                    })
                      .then((res) => {
                        console.log(res, '单个退款成功')
                        this.$message.success(res.msg)
                        resolve(res)
                      })
                      .catch((err) => {
                        console.log(err, '单个退款失败')
                        resolve(err)
                        error = true
                      })
                  }, 1000)
                }
              })
            })
          ).then((res) => {
            console.log(res, '批量退款成功')
            this.updateList()
            this.openDialog = false
            this.saveLoading = false

            if (res.filter(r => r.code === 0).length) {
              this.$alert(
                  res.map((r) => r.msg + '；').join('<br/>'),
                  '退款失败',
                  {
                    dangerouslyUseHTMLString: true,
                  }
              ).catch(() => {})
            } else {
              // code 都是 1，不用弹窗了
            }
          })
        } else {
          return false
        }
      })
    },
    // 取消
    cancel(formName) {
      this.openDialog = false
      this.$refs[formName].resetFields()
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-textarea__inner {
    width: 360px;
  }
  ::v-deep.el-form-item__label {
    padding-bottom: 0px;
  }
}
</style>
