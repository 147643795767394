<template>
  <LayoutFilter :onFilter="ok" :toggleCollapseEnable="true" :onReset="resetForm" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="用户名称：" label-width="70px" prop="user_keyword">
          <el-input v-model="form.user_keyword" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item :label="tenantVersion === 'school' ? '所属校友会：':'所属组织：'" label-width="85px" prop="organization">
          <el-input v-model="form.organization" placeholder="请选择">
          </el-input>
        </el-form-item>
        <el-form-item label="届数：" prop="number">
          <el-select v-model="number" placeholder="请选择届数">
            <el-option v-for="item in severa" :label="item.label" :value="item.value" :key="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="订单号：" label-width="60px" prop="keyword">
          <el-input v-model="form.orderid" placeholder="请输入订单号"></el-input>
        </el-form-item>

        <el-form-item label="订单状态：" prop="status">
          <el-select v-model="status" placeholder="请选择订单状态">
            <el-option v-for="item in statusOptions" :label="item.name" :key="item.id" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商户号：" label-width="70px" prop="merchant">
          <el-input v-model="form.merchant" placeholder="请输入商户号"></el-input>
        </el-form-item>
      </div>

      <div class="filter-item">
        <DatePeriodPicker label="提交时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { orderExport } from "../api/list";

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    selectArr: {
      type: Array,
      default: () => [],
    },
    severa: {
      type: Array,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      statusOptions: [
        { name: "已支付", id: 0 },
        { name: "未支付", id: 1 },
        { name: "已取消", id: 2 },
        { name: "已退款", id: 3 },
        { name: "退款中", id: 4 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出
    onExport() {
      const postData = { ...this.form, ids: this.selectArr, page: this.page };
      return orderExport(postData)
        .then()
        .catch(() => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    merchant: {
      get() {
        return this.form.merchant;
      },
      set(val) {
        this.form.merchant = val;
      },
    },
    number: {
      get() {
        return this.form.number == -1 ? "" : this.form.number;
      },
      set(val) {
        this.form.number = val;
      },
    },
    status: {
      get() {
        return this.form.status == -1 ? "" : this.form.status;
      },
      set(val) {
        this.form.status = val;
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },

};
</script>
